import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Button,
    Divider,
    useMediaQuery,
    useTheme,
    Snackbar,
    Alert,
    Typography,
    TextField,
    Radio,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";

const CalculationCar = ({ next, dataChange, formData = {} }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [carCost, setCarCost] = useState(formData.carCost || "");
    const [initialPayment, setInitialPayment] = useState(
        formData.initialPayment || ""
    );
    const [loanAmount, setLoanAmount] = useState(formData.loanAmount || "");
    const [loanTerm, setLoanTerm] = useState(formData.loanTerm || "");
    const [startDate, setStartDate] = useState(
        formData.startDate || dayjs().format("YYYY-MM-DD")
    );
    const [showResults, setShowResults] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [isTableModalOpen, setIsTableModalOpen] = useState(false);
    const [itemsToShow, setItemsToShow] = useState(10);

    // Функция обновления formData
    const updateFormData = useCallback(() => {
        dataChange(0, {
            ...formData,
            carCost,
            initialPayment,
            loanAmount,
            loanTerm,
            startDate,
        });
    }, [
        dataChange,
        formData,
        carCost,
        initialPayment,
        loanAmount,
        loanTerm,
        startDate,
    ]);

    useEffect(() => {
        updateFormData();
    }, [
        carCost,
        initialPayment,
        loanAmount,
        loanTerm,
        startDate,
        updateFormData,
    ]);

    const handleNext = () => {
        if (validateForm()) {
            next();
        } else {
            setErrorMessage("Пожалуйста, заполните все обязательные поля.");
            setOpenSnackbar(true);
        }
    };

    const validateForm = () => {
        const errors = {};
        const totalLoanTerm = Number(loanTerm);

        if (!loanTerm || totalLoanTerm < 0) {
            errors.loanTerm = "Пожалуйста, выберите срок кредита.";
        }

        if (!carCost) {
            errors.carCost = "Пожалуйста, введите стоимость автомобиля.";
        }

        if (initialPayment < 0) {
            errors.initialPayment =
                "Первоначальный взнос не может быть отрицательным.";
        }

        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);
            setShowResults(false);
            return false;
        } else {
            setErrorMessages({});
            setShowResults(true);
            return true;
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleCarCostChange = (event) => {
        const value = parseFloat(event.target.value.replace(/\s/g, "")) || 0;
        setCarCost(value);
        const initialPaymentValue = (value * 0.1).toFixed(0);
        setInitialPayment(initialPaymentValue);
        setLoanAmount((value - initialPaymentValue).toFixed(0));
    };

    const handleInitialPaymentChange = (event) => {
        const value = parseFloat(event.target.value.replace(/\s/g, "")) || 0;
        setInitialPayment(value);
        setLoanAmount((carCost - value).toFixed(0));
    };

    const handleLoanTermChange = (event) => {
        setLoanTerm(event.target.value);
    };

    const interestRate = 30;

    const handleCalculate = () => {
        if (validateForm()) {
            // Расчет происходит в calculateLoanDetails()
        }
    };

    const calculateLoanDetails = () => {
        const totalLoanTerm = Number(loanTerm);
        const loanAmount = carCost - initialPayment;
        const monthlyRate = interestRate / 100 / 12;
        let totalInterest = 0;
        let totalPayment = 0;
        let monthlyPayment = 0;
        let paymentSchedule = [];

        monthlyPayment =
            (loanAmount * monthlyRate) /
            (1 - Math.pow(1 + monthlyRate, -totalLoanTerm));
        totalPayment = monthlyPayment * totalLoanTerm;
        totalInterest = totalPayment - loanAmount;

        let balance = loanAmount;
        for (let i = 0; i < totalLoanTerm; i++) {
            const interestForMonth = balance * monthlyRate;
            const principalForMonth = monthlyPayment - interestForMonth;
            balance -= principalForMonth;

            paymentSchedule.push({
                paymentDate: dayjs(startDate)
                    .add(i + 1, "month")
                    .format("DD.MM.YYYY"),
                balance: balance > 0 ? balance : 0,
                interestForMonth,
                principalForMonth,
                monthlyPayment,
                cumulativeInterest:
                    (paymentSchedule[i - 1]?.cumulativeInterest || 0) +
                    interestForMonth,
                month: i + 1,
            });
        }

        return {
            loanAmount,
            monthlyPayment,
            totalInterest,
            totalPayment,
            paymentSchedule,
        };
    };

    const calculationResults =
        showResults && !Object.keys(errorMessages).length
            ? calculateLoanDetails()
            : null;

    let totalPrincipal = 0;
    let totalInterestInSchedule = 0;
    let totalMonthlyPayment = 0;

    if (calculationResults) {
        totalPrincipal = calculationResults.paymentSchedule.reduce(
            (sum, payment) => sum + payment.principalForMonth,
            0
        );

        totalInterestInSchedule = calculationResults.paymentSchedule.reduce(
            (sum, payment) => sum + payment.interestForMonth,
            0
        );

        totalMonthlyPayment = calculationResults.paymentSchedule.reduce(
            (sum, payment) => sum + payment.monthlyPayment,
            0
        );
    }

    const handleShowMore = () => {
        setItemsToShow((prev) => prev + 10);
    };

    return (
        <Box sx={{ padding: isMobile ? 2 : 3 }}>
            <Typography variant="h6">Кредитование</Typography>
            <Divider />
            <NumericFormat
                customInput={TextField}
                thousandSeparator=" "
                label="Общая стоимость автомобиля (₸)"
                value={carCost}
                onChange={handleCarCostChange}
                fullWidth
                margin="normal"
                required
                error={Boolean(errorMessages.carCost)}
                helperText={errorMessages.carCost}
            />
            <NumericFormat
                customInput={TextField}
                thousandSeparator=" "
                label="Первоначальный взнос(₸)"
                value={initialPayment}
                onChange={handleInitialPaymentChange}
                fullWidth
                margin="normal"
                required
                error={Boolean(errorMessages.initialPayment)}
                helperText={
                    errorMessages.initialPayment ||
                    `Минимальный взнос (10%): ${(
                        carCost * 0.1
                    ).toLocaleString()} ₸`
                }
            />
            <NumericFormat
                customInput={TextField}
                label="Сумма кредита (₸)"
                thousandSeparator=" "
                fullWidth
                value={loanAmount}
                margin="normal"
                disabled
            />
            <Box mt={2}>
                <FormLabel component="legend">Срок кредита</FormLabel>
                <RadioGroup
                    value={loanTerm}
                    onChange={handleLoanTermChange}
                    row
                >
                    {["12", "24", "36", "48", "60", "72", "84"].map((term) => (
                        <FormControlLabel
                            key={term}
                            value={term}
                            control={<Radio />}
                            label={`${term} мес`}
                        />
                    ))}
                </RadioGroup>
                {errorMessages.loanTerm && (
                    <Typography color="error">
                        {errorMessages.loanTerm}
                    </Typography>
                )}
            </Box>
            <TextField
                label="Дата выдачи"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Box textAlign="center" mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCalculate}
                >
                    Рассчитать
                </Button>
            </Box>
            {calculationResults && (
                <Collapse in={showResults}>
                    <Box mt={4}>
                        <Typography variant="h6" gutterBottom>
                            Результаты расчета
                        </Typography>
                        <Typography>
                            <strong>Сумма кредита:</strong>{" "}
                            {calculationResults.loanAmount.toLocaleString()} ₸
                        </Typography>
                        <Typography>
                            <strong>Ежемесячный платеж:</strong>{" "}
                            {calculationResults.monthlyPayment.toLocaleString()}{" "}
                            ₸
                        </Typography>
                        <Typography>
                            <strong>Сумма процентов:</strong>{" "}
                            {calculationResults.totalInterest.toLocaleString()}{" "}
                            ₸
                        </Typography>
                        <Typography>
                            <strong>Общая выплата:</strong>{" "}
                            {calculationResults.totalPayment.toLocaleString()} ₸
                        </Typography>
                        <Typography>
                            <strong>Переплата по кредиту:</strong>{" "}
                            {(
                                (calculationResults.totalInterest /
                                    calculationResults.loanAmount) *
                                100
                            ).toFixed(2)}
                            %
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Alert severity="info">
                            Данный ежемесячный платеж является предварительным и
                            несет в себе информационный характер. После полного
                            одобрения ежемесячный платеж может измениться!
                            Просим донести информацию до клиента!
                        </Alert>
                    </Box>
                    {/* Кнопка для открытия модального окна */}
                    <Box textAlign="center" mt={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setIsTableModalOpen(true)}
                        >
                            Показать график платежей
                        </Button>
                    </Box>
                </Collapse>
            )}

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                >
                    Продолжить
                </Button>
            </Box>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="warning"
                    sx={{ width: "100%" }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
            {/* Модальное окно с графиком платежей */}
            {calculationResults && (
                <Dialog
                    open={isTableModalOpen}
                    onClose={() => setIsTableModalOpen(false)}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle>График платежей по кредиту</DialogTitle>
                    <DialogContent dividers>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>№</TableCell>
                                        <TableCell>Дата платежа</TableCell>
                                        <TableCell>Остаток долга (₸)</TableCell>
                                        <TableCell>Начисление % (₸)</TableCell>
                                        <TableCell>
                                            Платеж в осн. долг (₸)
                                        </TableCell>
                                        <TableCell>Сумма платежа (₸)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {calculationResults.paymentSchedule
                                        .slice(0, itemsToShow)
                                        .map((payment, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {payment.month}
                                                </TableCell>
                                                <TableCell>
                                                    {payment.paymentDate}
                                                </TableCell>
                                                <TableCell>
                                                    {payment.balance.toLocaleString(
                                                        undefined,
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {payment.interestForMonth.toLocaleString(
                                                        undefined,
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {payment.principalForMonth.toLocaleString(
                                                        undefined,
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {payment.monthlyPayment.toLocaleString(
                                                        undefined,
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {itemsToShow <
                                        calculationResults.paymentSchedule
                                            .length && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                align="center"
                                            >
                                                <Button
                                                    onClick={handleShowMore}
                                                    color="primary"
                                                >
                                                    Показать больше
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <strong>Итого:</strong>
                                        </TableCell>
                                        <TableCell>
                                            {totalInterestInSchedule.toLocaleString(
                                                undefined,
                                                {
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {totalPrincipal.toLocaleString(
                                                undefined,
                                                {
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {totalMonthlyPayment.toLocaleString(
                                                undefined,
                                                {
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setIsTableModalOpen(false)}
                            color="primary"
                        >
                            Закрыть
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

export default CalculationCar;
