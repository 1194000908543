import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Container,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import CalculationCar from "./steps/CalculationCar";

import SalonInformation from "./steps/SalonInformation";
import ClientContactInfo from "./steps/ClientContactInfo";
import SubmitForm from "./steps/SubmitForm";
import CarDetails from "./steps/CarDetails";
import EmergencyContacts from "./steps/EmergencyContacts";
import WorkDetails from "./steps/WorkDetails";
import ResidentialAddress from "./steps/ResidentialAddress";
import UploadDocuments from "./steps/UploadsDocuments";
import CapturePhoto from "./steps/CapturePhoto";
import ConsentForm from "./steps/ConsentForm";
import SubmissionResult from "./steps/SubmissionResult";

const steps = [
    "Расчет автомобиля",
    "Данные автомобиля",
    "Данные клиента",
    "Адрес проживания",
    "Место работы",
    "Экстренные контакты",
    "Загрузка документов",
    "Фото клиента",
    "Информация салона",
    "Согласие на сбор данных",
    "Отправить",
];

const MultiStepForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [direction, setDirection] = useState(1);
    const [submissionResult, setSubmissionResult] = useState(null);
    const stepperRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [noRegistration, setNoRegistration] = useState(false);

    const handleNext = () => {
        setDirection(1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setDirection(-1);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleDataChange = (step, data) => {
        setFormData({ ...formData, [step]: data });

        if (step === 1 && data.noRegistration !== undefined) {
            setNoRegistration(data.noRegistration);
        }
    };

    const handleSubmit = async () => {
        const success = true; // Replace with actual success logic
        setSubmissionResult(success);
        setActiveStep(steps.length);
    };

    const handleRetry = () => {
        setFormData({});
        setSubmissionResult(null);
        setActiveStep(0);
    };

    useEffect(() => {
        if (stepperRef.current) {
            const activeStepElement = stepperRef.current.querySelector(
                `.MuiStep-horizontal:nth-child(${activeStep + 1})`
            );
            if (activeStepElement) {
                activeStepElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                });
            }
        }
    }, [activeStep]);

    const startDragging = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - stepperRef.current.offsetLeft);
        setScrollLeft(stepperRef.current.scrollLeft);
    };

    const stopDragging = () => {
        setIsDragging(false);
    };

    const onDrag = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - stepperRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Скорость скролла
        stepperRef.current.scrollLeft = scrollLeft - walk;
    };

    return (
        <Container maxWidth={isMobile ? "sm" : "lg"} sx={{ padding: "20px" }}>
            <Typography variant="h4" align="center" gutterBottom>
                Форма заявки
            </Typography>
            <Box
                ref={stepperRef}
                sx={{
                    display: "flex",
                    overflowX: "auto",
                    marginBottom: 2,
                    userSelect: "none",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    // Новые стили для поддержки скролла
                    maxWidth: isMobile ? "100%" : "80%",
                    margin: "0 auto",
                    padding: "10px 0",
                    alignItems: "center",
                }}
                onMouseDown={startDragging}
                onMouseUp={stopDragging}
                onMouseLeave={stopDragging}
                onMouseMove={onDrag}
            >
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        width: "100%",
                    }}
                >
                    {steps.map((label) => (
                        <Step key={label} sx={{ minWidth: "100px" }}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box mt={2} sx={{ position: "relative", height: "auto" }}>
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        key={activeStep}
                        custom={direction}
                        initial={{
                            opacity: 0,
                            x: direction > 0 ? 100 : -100,
                            position: "absolute",
                            width: "100%",
                        }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{
                            opacity: 0,
                            x: direction > 0 ? -100 : 100,
                            position: "absolute",
                            width: "100%",
                        }}
                        transition={{ duration: 0.5 }}
                    >
                        {getStepContent(
                            activeStep,
                            handleNext,
                            handleBack,
                            handleDataChange,
                            formData,
                            handleSubmit,
                            handleRetry,
                            submissionResult,
                            noRegistration
                        )}
                    </motion.div>
                </AnimatePresence>
            </Box>
        </Container>
    );
};

const getStepContent = (
    step,
    handleNext,
    handleBack,
    handleDataChange,
    formData,
    handleSubmit,
    handleRetry,
    submissionResult,
    noRegistration
) => {
    if (step === steps.length) {
        return (
            <SubmissionResult
                success={submissionResult}
                onRetry={handleRetry}
            />
        );
    }

    switch (step) {
        case 0:
            return (
                <CalculationCar
                    next={handleNext}
                    dataChange={handleDataChange}
                    formData={formData[0]}
                />
            );
        case 1:
            return (
                <CarDetails
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[1]}
                />
            );

        case 2:
            return (
                <ClientContactInfo
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[2]}
                />
            );

        case 3:
            return (
                <ResidentialAddress
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[3]}
                />
            );
        case 4:
            return (
                <WorkDetails
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[4]}
                />
            );
        case 5:
            return (
                <EmergencyContacts
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[5]}
                />
            );
        case 6:
            return (
                <UploadDocuments
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[6]}
                    noRegistration={noRegistration}
                />
            );
        case 7:
            return (
                <CapturePhoto
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[7]}
                    clientName={formData[2]?.clientFullName}
                />
            );
        case 8:
            return (
                <SalonInformation
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[8]}
                />
            );
        case 9:
            return (
                <ConsentForm
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={{
                        ...formData[9],
                        fullName: formData[2]?.clientFullName,
                        iin: formData[2]?.iin,
                    }}
                />
            );
        case 10:
            return (
                <SubmitForm
                    back={handleBack}
                    submit={handleSubmit}
                    formData={formData}
                />
            );
        default:
            return "Unknown step";
    }
};

export default MultiStepForm;
