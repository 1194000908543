import React, { useState } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

const telegramChat = [
    { tg_name: "avtomarket_01", tg_id: -1002179259252 },
    { tg_name: "avtomarket_02", tg_id: -1002198599808 },
    { tg_name: "avtomarket_04", tg_id: -1002173220070 },
    { tg_name: "avtomarket_05", tg_id: -1002187397782 },
    { tg_name: "avtomarket_06", tg_id: -1002198855905 },
    { tg_name: "avtomarket_07", tg_id: -1002193432696 },
    { tg_name: "avtomarket_08", tg_id: -1002213755877 },
    { tg_name: "avtomarket_09", tg_id: -1002163913617 },
    { tg_name: "avtomarket_12", tg_id: -1002165220416 },
    { tg_name: "avtomarket_14", tg_id: -1002164536071 },
    { tg_name: "avtomarket_17", tg_id: -1002149295025 },
];

const SalonInformation = ({ next, dataChange, formData = {}, back }) => {
    const [salonName, setSalonName] = useState(formData.salonName || "");
    const [managerName, setManagerName] = useState(formData.managerName || "");
    const [managerPhone, setManagerPhone] = useState(
        formData.managerPhone || ""
    );
    const [tgChat, setTgChat] = useState(formData.tgChat || {});
    const [errors, setErrors] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const validateForm = () => {
        const newErrors = {};
        if (!salonName.trim())
            newErrors.salonName = "Название салона обязательно";
        if (!managerName.trim())
            newErrors.managerName = "Полное имя Менеджера обязательно";
        if (managerPhone.trim().length < 15)
            newErrors.managerPhone =
                "Телефон номер должен быть заполнен до 15 символов";
        if (!tgChat.tg_id) newErrors.tgChat = "Выберите точку оформления";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateForm()) {
            dataChange(8, { salonName, managerName, managerPhone, tgChat });
            next();
        }
    };
    return (
        <Box
            sx={{
                padding: isMobile ? 2 : 3,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Typography variant="h6">Информация салона</Typography>
            <TextField
                label="Название салона"
                value={salonName}
                onChange={(e) => setSalonName(e.target.value)}
                fullWidth
                margin="normal"
                required
                error={!!errors.salonName}
                helperText={errors.salonName}
            />
            <TextField
                label="Полное имя Менеджера"
                value={managerName}
                onChange={(e) => setManagerName(e.target.value)}
                fullWidth
                margin="normal"
                required
                error={!!errors.managerName}
                helperText={errors.managerName}
            />
            <MuiTelInput
                defaultCountry="KZ"
                onlyCountries={["KZ"]}
                label="Телефон номер Менеджера"
                value={managerPhone}
                onChange={(newPhone) => setManagerPhone(newPhone)}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 15 }}
                required
                error={!!errors.managerPhone}
                helperText={errors.managerPhone}
            />
            <FormControl
                fullWidth
                margin="normal"
                required
                error={!!errors.tgChat}
            >
                <InputLabel id="tgChat-label">
                    Выберите точку оформления
                </InputLabel>
                <Select
                    labelId="tgChat-label"
                    value={tgChat.tg_id || ""}
                    label="Точка оформления"
                    placeholder="Выберите точку оформления"
                    onChange={(e) => {
                        const selectedChat = telegramChat.find(
                            (chat) => chat.tg_id === e.target.value
                        );
                        setTgChat(selectedChat);
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 48 * 4.5 + 8, // Высота на основе 4.5 пункта + отступы
                                width: 250, // Ширина меню
                            },
                        },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                    }}
                >
                    {telegramChat.map((chat) => (
                        <MenuItem key={chat.tg_id} value={chat.tg_id}>
                            {chat.tg_name}
                        </MenuItem>
                    ))}
                </Select>
                {errors.tgChat && (
                    <Typography color="error">{errors.tgChat}</Typography>
                )}
            </FormControl>

            <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" onClick={back}>
                    Вернуться
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                >
                    Продолжить
                </Button>
            </Box>
        </Box>
    );
};

export default SalonInformation;
