import React, { useState } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

const ClientContactInfo = ({ next, back, dataChange, formData = {} }) => {
    const [clientFullName, setClientFullName] = useState(
        formData.clientFullName || ""
    );
    const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber || "");
    const [iin, setIIN] = useState(formData.iin || "");
    const [maritalStatus, setMaritalStatus] = useState(
        formData.maritalStatus || ""
    );
    const [errors, setErrors] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const validateForm = () => {
        const newErrors = {};
        if (!clientFullName.trim())
            newErrors.clientFullName = "ФИО Клиента обязательно";
        if (phoneNumber.trim().length < 15)
            newErrors.phoneNumber =
                "Телефон номер должен быть заполнен до 15 символов";
        if (iin.trim().length !== 12)
            newErrors.iin = "ИИН должен содержать ровно 12 цифр";
        if (!maritalStatus)
            newErrors.maritalStatus = "Семейное положение обязательно";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateForm()) {
            dataChange(2, { clientFullName, phoneNumber, iin, maritalStatus });
            next();
        }
    };

    return (
        <Box sx={{ padding: isMobile ? 2 : 3 }}>
            <Typography variant="h6">Информация клиента</Typography>
            <TextField
                label="ФИО Клиента полностью"
                value={clientFullName}
                onChange={(e) => setClientFullName(e.target.value)}
                fullWidth
                margin="normal"
                required
                error={!!errors.clientFullName}
                helperText={errors.clientFullName}
            />
            <MuiTelInput
                defaultCountry="KZ"
                onlyCountries={["KZ"]}
                label="Телефон номер клиента"
                value={phoneNumber}
                onChange={(newPhone) => setPhoneNumber(newPhone)}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 15 }}
                required
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
            />
            <TextField
                type="text"
                label="ИИН"
                value={iin}
                onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d{0,12}$/.test(input)) {
                        setIIN(input);
                    }
                }}
                fullWidth
                margin="normal"
                required
                error={!!errors.iin}
                helperText={errors.iin}
            />
            <FormControl
                fullWidth
                margin="normal"
                required
                error={!!errors.maritalStatus}
            >
                <InputLabel>Семейное положение</InputLabel>
                <Select
                    value={maritalStatus}
                    onChange={(e) => setMaritalStatus(e.target.value)}
                >
                    <MenuItem value="Холост/Не замужем">
                        Холост/Не замужем
                    </MenuItem>
                    <MenuItem value="Женат/Замужем">Женат/Замужем</MenuItem>
                    <MenuItem value="Разведен/Разведена">
                        Разведен/Разведена
                    </MenuItem>
                    <MenuItem value="Вдовец/Вдова">Вдовец/Вдова</MenuItem>
                </Select>
            </FormControl>
            <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" onClick={back}>
                    Вернуться
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                >
                    Продолжить
                </Button>
            </Box>
        </Box>
    );
};

export default ClientContactInfo;
