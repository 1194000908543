import React, { useState } from "react";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    CircularProgress,
    Snackbar,
    Alert,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import JSZip from "jszip";
import generateDocx from "../utils/generateDocument.js";
import sendFileToTelegram from "../utils/sendFileToTelegram.js";

const SubmitForm = ({ back, submit, formData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);

        const zip = new JSZip();

        const docBlob = await generateDocx(formData);
        zip.file("Данные клиента.docx", docBlob);

        const dataFolder = zip.folder("Данные");
        const documentSteps = [
            { key: "idDocument", label: "Удостоверение" },
            { key: "techPassport", label: "Техпаспорт" },
            { key: "bankStatement", label: "Выписка" },
            { key: "additionalStatement", label: "Дополнительная_выписка" },
        ];

        documentSteps.forEach(({ key, label }) => {
            const files = formData[6]?.[key];
            if (Array.isArray(files) && files.length > 0) {
                let file = files[0];
                const fileExtension = file.name.split(".").pop();
                const newFileName = `${label}.${fileExtension}`;
                file = new File([file], newFileName, { type: file.type });
                if (file instanceof Blob) {
                    dataFolder.file(newFileName, file);
                }
            }
        });

        // Добавление фото из formData[8]?.photo
        const photo = formData[7]?.photo;
        if (photo) {
            const photoBlob = await fetch(photo.data).then((res) => res.blob());
            dataFolder.file(photo.name, photoBlob);
        }

        // Generate ZIP file
        const zipBlob = await zip.generateAsync({ type: "blob" });
        const clientFullName = formData[2]?.clientFullName || "client";

        // Send the ZIP file to Telegram
        const success = await sendFileToTelegram(
            formData[8]?.tgChat.tg_id, // Отправка на тот канал который выбрал пользователь
            zipBlob,
            clientFullName
        );

        setLoading(false); // Stop loading animation

        setSnackbarMessage(
            success
                ? "Данные успешно отправлены!"
                : "Ошибка при отправке данных."
        );
        setSnackbarSeverity(success ? "success" : "error");
        setSnackbarOpen(true);

        // Call the callback to switch to the result form
        submit(success);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const DataTable = ({ data }) => {
        if (!data) return null;

        // Проверяем, содержит ли объект данные о кредите
        if (
            data.carCost ||
            data.initialPayment ||
            data.loanAmount ||
            data.loanTerm
        ) {
            // Это объект с результатами расчета кредита
            return (
                <>
                    <TableRow>
                        <TableCell>Стоимость автомобиля</TableCell>
                        <TableCell>
                            {data.carCost.toLocaleString() || "Не указано"} ₸
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Первоначальный взнос</TableCell>
                        <TableCell>
                            {data.initialPayment.toLocaleString() ||
                                "Не указано"}{" "}
                            ₸
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Срок кредита</TableCell>
                        <TableCell>
                            {data.loanTerm.toLocaleString() || "Не указано"}
                        </TableCell>
                    </TableRow>
                </>
            );
        }

        // Фолбэк для неизвестных данных
        return (
            <TableRow>
                <TableCell colSpan={2} align="center">
                    Нет данных для отображения
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Box sx={{ padding: isMobile ? 2 : 3 }}>
            <Typography
                variant="h6"
                sx={{ marginBottom: 2, textAlign: "center" }}
            >
                Проверьте ваши данные
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Категория</TableCell>
                            <TableCell>Данные</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Точка оформления</TableCell>
                            <TableCell>
                                {formData[8]?.tgChat.tg_name || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Название салона</TableCell>
                            <TableCell>
                                {formData[8]?.salonName || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Имя менеджера</TableCell>
                            <TableCell>
                                {formData[8]?.managerName || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Телефон менеджера</TableCell>
                            <TableCell>
                                {formData[8]?.managerPhone || "Не указано"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>ФИО клиента</TableCell>
                            <TableCell>
                                {formData[2]?.clientFullName || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Телефон клиента</TableCell>
                            <TableCell>
                                {formData[2]?.phoneNumber || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>ИИН клиента</TableCell>
                            <TableCell>
                                {formData[2]?.iin || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Семейный статус</TableCell>
                            <TableCell>
                                {formData[2]?.maritalStatus || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <DataTable data={formData[0]} />
                        <TableRow>
                            <TableCell>Марка автомобиля</TableCell>
                            <TableCell>
                                {formData[1]?.brand || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Модель автомобиля</TableCell>
                            <TableCell>
                                {formData[1]?.model || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Год выпуска</TableCell>
                            <TableCell>
                                {formData[1]?.year || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Руль</TableCell>
                            <TableCell>
                                {formData[1]?.steering || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Объем двигателя</TableCell>
                            <TableCell>
                                {formData[1]?.engineVolume || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>VIN</TableCell>
                            <TableCell sx={{ textTransform: "uppercase" }}>
                                {formData[1]?.vin || "Не указано"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Улица</TableCell>
                            <TableCell>
                                {formData[3]?.street || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Номер дома</TableCell>
                            <TableCell>
                                {formData[3]?.houseNumber || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Квартира</TableCell>
                            <TableCell>
                                {formData[3]?.apartment || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Подъезд</TableCell>
                            <TableCell>
                                {formData[3]?.entrance || "Не указано"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Должность</TableCell>
                            <TableCell>
                                {formData[4]?.position || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Место работы</TableCell>
                            <TableCell>
                                {formData[4]?.workPlace || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Адрес работы</TableCell>
                            <TableCell>
                                {formData[4]?.workAddress || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Контакты работы</TableCell>
                            <TableCell>
                                {formData[4]?.workContacts || "Не указано"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2} align="center">
                                Экстренные контакты
                            </TableCell>
                        </TableRow>
                        {formData[5]?.map((contact, index) => (
                            <TableRow key={index}>
                                <TableCell>{`Контакт ${index + 1}`}</TableCell>
                                <TableCell>{`Имя: ${
                                    contact.name || "Не указано"
                                }, Телефон: ${
                                    contact.phone || "Не указано"
                                }`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={back}
                    disabled={loading}
                >
                    Назад
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        "Отправить"
                    )}
                </Button>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SubmitForm;
