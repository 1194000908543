import {
    Document,
    Packer,
    Paragraph,
    Table as DocTable,
    TableCell as DocTableCell,
    TableRow as DocTableRow,
    BorderStyle,
    HeadingLevel,
} from "docx";

const toUpperCase = (text) => text?.toUpperCase() || "Не указано";

const generateDocx = async (data) => {
    const doc = new Document({
        styles: {
            default: {
                paragraph: {
                    spacing: { after: 200 },
                },
            },
        },
        sections: [
            {
                properties: {},
                children: [
                    new Paragraph({
                        text: "Информация заявки",
                        heading: HeadingLevel.HEADING_1,
                        style: "default",
                    }),
                    new DocTable({
                        rows: [
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Точка оформления",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    data[8]?.tgChat?.tg_name ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Название салона",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    data[8]?.salonName ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Имя менеджера",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    data[8]?.managerName ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Контакты менеджера",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    data[8]?.managerPhone ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Данные клиента",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    `ФИО: ${data[2]?.clientFullName}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `ИИН: ${data[2]?.iin}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `Семейное положение: ${data[2]?.maritalStatus}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `Телефон номер : ${data[2]?.phoneNumber}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Адрес проживания",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    `Город: ${data[3]?.city}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `Улица: ${data[3]?.street}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `Дом: ${data[3]?.houseNumber}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `Подъезд: ${data[3]?.entrance}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `Квартира: ${data[3]?.apartment}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Место работы",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    `Должность: ${data[4]?.position}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `Место работы: ${data[4]?.workPlace}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `Адрес работы: ${data[4]?.workAddress}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text:
                                                    `Контакты работы: ${data[4]?.workContacts}` ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Детали автомобиля",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: `Автомобиль не имеет первичной регистрации в РК: ${
                                                    data[1]?.noRegistration
                                                        ? "Да"
                                                        : "Нет" || "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Марка: ${
                                                    data[1]?.brand ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Модель: ${
                                                    data[1]?.model ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Год: ${
                                                    data[1]?.year ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Объем двигателя: ${
                                                    data[1]?.engineVolume ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `VIN: ${toUpperCase(
                                                    data[1]?.vin
                                                )}`,
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Кредитные данные",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: `Стоимость авто: ${
                                                    data[0]?.carCost?.toLocaleString() ||
                                                    "Не указано"
                                                } ₸`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Первоначальный взнос: ${
                                                    data[0]?.initialPayment?.toLocaleString() ||
                                                    "Не указано"
                                                } ₸`,
                                                style: "default",
                                            }),

                                            new Paragraph({
                                                text: `Сумма кредита: ${
                                                    data[0]?.loanAmount ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Срок кредита: ${
                                                    data[0]?.loanTerm ||
                                                    "Не указано"
                                                } месяцев`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Дата отправки: ${
                                                    data[0]?.startDate ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),

                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Экстренные контакты",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                    new DocTableCell({
                                        children: [
                                            ...(data[5] || []).map(
                                                (contact, index) =>
                                                    new Paragraph({
                                                        text: `Контакт ${
                                                            index + 1
                                                        }: Имя: ${
                                                            contact.name ||
                                                            "Не указано"
                                                        }, Телефон: ${
                                                            contact.phone ||
                                                            "Не указано"
                                                        }`,
                                                        style: "default",
                                                    })
                                            ),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),
                ],
            },
        ],
    });

    const blob = await Packer.toBlob(doc);
    return blob;
};

export default generateDocx;
